import { ComputedRef, Ref } from '@nuxtjs/composition-api'
import { Dictionary } from 'ramda'

export enum CountryCode {
  NL = 'NL',
  BE = 'BE',
  ES = 'DE',
}

export interface Link {
  key: string
  text: string
  url: string
}

export interface WorkingDate {
  isTomorrow: boolean
  date: Date
}

export enum InitStatus {
  NotInited = 'NotInited',
  Initing = 'Initing',
  Success = 'Success',
  Failed = 'Failed',
}

export enum ApiStatus {
  Ok = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  ServerError = 500,
  InvalidData = 600, // Return data invalid
}

export interface ApiResult<T> {
  data?: T
  message?: string
}

export enum SocialKeys {
  Twitter = 'TwitterUrl',
  Instagram = 'InstagramUrl',
  Facebook = 'FacebookUrl',
  Pinterest = 'PinterestUrl',
  Youtube = 'YoutubeUrl',
  Linkedin = 'LinkedinUrl',
  Mail = 'MailUrl',
}

export interface SocialLink {
  name?: string
  key: string
  value: string
}

export interface SpecialWebNode {
  actie?: string
  'meer=minder'?: string
  'op=op'?: string
  homePopular?: string
  sampleProducts?: number
}

export interface ColorConfig {
  textColor: string
  backgroundColor: string
}

export interface LabelColor extends ColorConfig {
  name: string
}

export interface LabelColorConfig {
  default: ColorConfig
  labels: Record<string, LabelColor>
}

export interface LoyaltyCard {
  optInNewCustomersByDefault: boolean
}

export interface GlobalConfig {
  gtmEnabled: boolean
  gtmSetting: string

  webStoreId?: number
  deliveryCutoffLarge?: string
  deliveryCutoffSmall?: string
  pickupCutoff?: string
  socialLinks?: SocialLink[]
  specialWebNodes: SpecialWebNode
  minimumFreeShippingLarge: number
  minimumFreeShippingSmall: number
  discountBackgroundColors?: Dictionary<string>
  loyaltyCustomerCodes?: string[]
  stockCount?: number
  minimumPointsToRedeem?: number
  holidays?: Date[]
  discountLabelColors?: LabelColorConfig
  productLabelColors?: LabelColorConfig
  shippingRegions?: string[]
  loyaltyCard?: LoyaltyCard
  defaultPriceListId?: number
}

export enum AnchorHash {
  Description = 'description',
  Ingredients = 'ingredients',
  Reviews = 'reviews',
  Connections = 'connections',
}

export interface Media {
  type: string
  src: string
  thumb?: string
  order: number
}

export interface Video extends Media {
  poster?: string
  videoType?: string
}

export type ReactiveValue<T> = Ref<T> | ComputedRef<T>

export enum PermissionStatus {
  Denied = 'denied',
  Granted = 'granted',
  Prompt = 'prompt',
}

export interface CookiePreference {
  personal: boolean
  social: boolean
  advertising: boolean
  statistics: boolean
}
